.map-container {
  height: 100%;
  width: 100%;

  .erd {
    width: 100%;
    height: 100%;
    position: relative;
    background-size: cover;
    background-image: url('../assets/images/erd.PNG');
    background-repeat: no-repeat;
  }

  .szazhalombatta {
    width: 100%;
    height: 100%;
    position: relative;
    background-size: cover;
    background-image: url('../assets/images/szazhalombatta.PNG');
    background-repeat: no-repeat;
  }
}