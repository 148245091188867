$defaultFontSize: 16px;

@mixin menu-item {
  a {
    position: relative;
    margin: 0 15px;
    text-decoration: none;
    color: $fontColorPrimary;

    &[aria-selected="true"] {
      background-color: black;
    }

    .svg-inline--fa {
      margin-right: 8px;
      color: $decorationColorPrimary;
    }

    &.active-nav-item:not(.mobile-nav-item) {
      &::after {
        content: '';
        border-bottom: 1px solid $fontColorPrimary;
        position: absolute;
        left: -5px;
        right: -5px;
        bottom: 0;
      }
    }
  }
}

.site-header {
  position: relative;
  background-color: $backGroundColorPrimary;
  color: $fontColorPrimary;
  font-size: $defaultFontSize;

  height: $siteHeaderHeight;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @if $header-align-left {
    flex-direction: row-reverse;
  } @else {
    flex-direction: row;
  }

  > div {
    margin: 0 10px;
  }

  .links {
    @include menu-item();
  }

  .language-selector {
    div[class$="-container"] {
      div[class$="-control"] {
        div[class$="-placeholder"] {
          text-transform: uppercase;
        }
      }
    }
  }

  .mobile-site-header-menu-component {
    > div > span {
      background: $decorationColorPrimary !important;
    }
  }
}

.mobile-site-header-menu {
  margin: 13px 0px 0px -9px;
  background-color: $backGroundColorSecondary;

  box-shadow: 0 1px 3px $backGroundColorPrimary;
  border: none;

  .rc-dropdown-menu-item {
    padding: 0;
    display: flex;

    @include menu-item();
    a {
      position: relative;
      padding: 5px 15px 5px 40px;
      margin: 0;
      width: 100%;
      font-size: $defaultFontSize;

      svg {
        position: absolute;
        left: 10px;
        top: 8px;
      }

      &.active-nav-item {
        background-color: $backGroundColorPrimary;
      }
    }

    &.rc-dropdown-menu-item-selected {
      background-color: inherit;
      &:after {
        display: none;
      }
    }
  }
}
