//Etiya style
//$backGroundColorPrimary: #22223A;
//$fontColorPrimary: #FFFFFF;
//$decorationColorPrimary: #D56C2C;

//Jean-Baptiste Kaloya Portfolio
$backGroundColorPrimary: #0F2557;
$backGroundColorSecondary: #28559A;
$fontColorPrimary: #FFFFFF;
$fontColorSecondary: #7ED5EA;
$decorationColorPrimary: #63BCE5;
$decorationColorSecondary: #4B9FE1;

$pageBackgroundColor: #e3e6ed;
