body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.app {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.app-content {
  height: calc(100vh - #{$siteHeaderHeight});
}

.app-logo {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px !important;
  width: calc(5vw - 20px);
  min-width: 50px;
  cursor: pointer;

  label {
    cursor: pointer;
    font-size: 12px;
  }

  &.is-mobile {
    position: absolute;
    right: calc(5vw - 20px);
  }
}
