.ReactModal__Content[aria-label="modal-news"] {
  top: 100px !important;
  right: 200px !important;
  left: 200px !important;
  bottom: 100px !important;
}

.ReactModal__Content[aria-label="modal-news-mobile"] {
  top: 10px !important;
  right: 10px !important;
  left: 10px !important;
  bottom: 10px !important;
}

.modal-news-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  div:nth-child(2) {
    flex: 1;
  }
}
