:root {
  --position: 10px;
  --direction: 1;
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes slide {
  0% { opacity:0; transform: translateX(15px); }
  3% { opacity:1; transform: translateX(10px); }
  6% { opacity:1; transform: translateX(5px); }
  9% { opacity:0; transform: translateX(0); }
  15% { opacity:0; transform: translateX(15px); }
  18% { opacity:1; transform: translateX(10px); }
  21% { opacity:1; transform: translateX(5px); }
  24% { opacity:0; transform: translateX(0); }
}
