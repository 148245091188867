.search-input {
  width: fit-content;
  height: fit-content;
  position: relative;
  border-radius: 4px;
  background-color: white;

  .search-icon {
    position: absolute;
    height: 100%;
    width: 30px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  input {
    padding: 5px 25px 5px 30px;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 19px;

    &::placeholder {
      font-size: 14px;
      font-weight: 300;
      color: lightgray;
    }
  }

  .clear-search-input {
    position: absolute;
    right: 0;
    margin-right: 5px;
    top: 0;
    height: 100%;
    cursor: pointer;
    display: flex !important;
    align-items: center;
    justify-content: center;

    svg {
      color: gray;
    }
  }
}
