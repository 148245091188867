.dropdown {
  div[class$="-container"] {
    div[class$="-control"] {
      min-height: unset;
      box-shadow: none;

      div:first-child {
        div[class$="-placeholder"] {
          position: relative;
          top: unset;
          transform: unset;
        }
      }

      div:last-child {
        div[class$="-indicatorContainer"] {
          padding: 0;
        }
      }
    }
  }
}

.primary-dropdown-colors {
  div[class$="-container"] {
    span[aria-live="polite"] {
      display: none;
    }

    div[class$="-control"] {
      border-color: $decorationColorPrimary;
      background-color: $backGroundColorSecondary;
      color: $fontColorSecondary;

      &:hover {
        border-color: $decorationColorSecondary;
      }

      div[class$="-placeholder"]  {
        color: $fontColorSecondary;
      }

      div[class$="-IndicatorsContainer"]  {
        span[class$="-indicatorSeparator"] {
          display: none;
        }

        div[class$="-IndicatorContainer"] {
          color: $decorationColorPrimary;

          &:hover {
            color: $decorationColorPrimary !important;
          }
        }
      }
    }

    div[class$="-menu"] {
      background-color: $backGroundColorSecondary;
      color: $fontColorSecondary;

      div[class$="-MenuList"] {
        div[class$="-option"] {
          &:hover {
            background-color: $backGroundColorPrimary;
          }
        }
      }
    }
  }
}
