.table-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 90vw;
}

.table-content {
  .ReactVirtualized__Table__headerColumn:not(:first-child):not(.no-padding) > * {
    padding-left: 20px;
  }

  .ReactVirtualized__Table__rowColumn:not(:first-child) > * {
    padding-left: 20px;
  }

  &:not(.withoutScrollbar) .ReactVirtualized__Table__Grid {
    height: 2px !important;
  }

  display: flex;
  flex-direction: column;
  flex: 1;

  .ReactVirtualized__Table__headerRow {
    cursor: default;
  }

  .ReactVirtualized__Table__sortableHeaderColumn {
    cursor: pointer;
  }

  .ReactVirtualized__Table__Grid, .ReactVirtualized__Table__headerColumn {
    outline: 0;
    //overflow: unset !important; //TODO apply this for custom scrollbar
  }

  .ReactVirtualized__Table__headerRow, .ReactVirtualized__Table__row {
    display: flex;
    align-items: center;
    outline: 0;
    cursor: pointer;
  }
}
