.landscape-menu {
  width: 100%;
  height: 100%;
  position: relative;

  background-image: url('../assets/images/landscape-menu-bg.jpg');
  background-repeat: no-repeat;

  &.mobile {
    transition: background-position 0.5s;

    .navigation-helper-icon {
      $iconFontSize: 40px;

      position: absolute;
      bottom: calc(50% - (#{$iconFontSize} / 2));
      color: rgba(255,255,255, 0.2);
      font-size: $iconFontSize;

      //-webkit-animation: heartbeat 2.5s ease-in-out infinite both;
      //animation: heartbeat 2.5s ease-in-out infinite both;

      &.left {
        left: 5px;

        svg {
          left: 0;

          &:last-child {
            opacity: 0;
            animation: slide 4s linear infinite;
          }
        }
      }

      &.right {
        right: 5px;

        svg {
          right: 0;

          &:first-child {
            opacity: 0;
            right: 15px;
            animation: slide 4s linear infinite;
            animation-direction: reverse;
          }
        }
      }

      svg {
        position: absolute;
        bottom: 0;
      }
    }
  }

  display: flex;

  .landscape-menu-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);

    text-decoration: none;
    font-size: 28px;
    color: $fontColorPrimary;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }

    &:not(:last-child){
      margin-right: 2px;
    }
  }
}
