.card-view {
  .contact-card {
    position: relative;
    margin: 10px;
    min-width: 500px;

    background-color: white;
    box-shadow: 5px 8px 8px 0px rgba(40,85,154,0.4);

    &.is-mobile {
      min-width: unset;
      width: 100%;
    }

    > div {
      position: relative;

      &:not(.map) {
        display: flex;
        justify-content: center;
      }

      &.open-time {
        flex-direction: column;
        align-items: center;

        > div:not(:first-child) {
          display: flex;

          > *:not(:first-child) {
            margin-left: 5px;
          }
        }
      }
    }

    .map {
      height: 50vh;
    }

    .title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
      color: $backGroundColorPrimary;

      &.small {
        font-size: 18px;
      }
    }

    .label {
      font-size: 16px;
      margin-bottom: 5px;
      color: $backGroundColorSecondary;
    }
  }
}
