.phone-link {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  margin: 0 25vw !important;

  a {
    color: white;
    text-decoration: none;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  svg {
    color: green;
    width: 25px;
    height: 25px;
  }

  span {
    margin-top: 2px;
    font-size: 10px;
  };

  &:not(.is-mobile) {
    display: none;
  }
}
