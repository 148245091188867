.page {
  height: 100%;
  background-color: $pageBackgroundColor;

  .column-style-content {
    margin: 40px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.card-view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: calc(100% - 10vh);

  padding: 5vh 5vw;

  div[class$="-card"] {
    position: relative;
    flex: 1;
    height: fit-content;
  }
}

.page-view {
  min-height: calc(100% - 20vh);
  height: auto;
  position: relative;
  padding: 5vh 5vw;
  margin: 5vh;

  background-color: white;
  box-shadow: 5px 8px 8px 0px rgba(40,85,154,0.4);

  &.mobile {
    min-height: calc(100% - 14vh);
    margin: 2vh;
  }

  h1 {
    color: $backGroundColorPrimary;
    text-decoration: underline;
  }

  h2 {
    font-size: 1em;
  }

  li.main {
    font-size: 1.5em;

    > *:not(h2) {
      font-size: 0.7em;
    }
  }

  div, ol, ul, strong, label {
    color: $backGroundColorPrimary;
  }

  div, ol, ul {
    margin-bottom: 10px;
  }

  .important {
    text-decoration: underline;
  }
}
